<template>
  <div class="inform-notice container">
    <section>
      <!-- left -->
      <article class="listpage-left-title">
        <div class="line1">
          <div>NOTICE</div>
          <div>通知公告</div>
        </div>
        <div class="line2"><div class="active">通知公告</div></div>
      </article>

      <!-- right列表 -->
      <article class="right-list">

        <div class="search-con">
          <el-input placeholder="请输入标题名称" v-model="searchtitle" />
          <el-button type="primary" @click="currentPage = 1; renderList();">查询</el-button>
          <el-button @click="searchtitle = null; currentPage = 1; renderList();">重置</el-button>
        </div>

        <section v-if="list.length > 0">
          <ul class="list">
            <li v-for="v in list" :key="v.id">
              <div class="date">
                <div>{{ filterTime(v.modifyTime)[2] }}</div>
                <div>{{ filterTime(v.modifyTime)[0] + '/' + filterTime(v.modifyTime)[1] }}</div>
              </div>
              <div
                class="content"
                @click="$router.push({ name: '通知公告详情',query:{informNoticeId:v.id} })"
              >
                <div class="title">{{ v.title }}</div>
                <div class="text over-2-line">{{ delHtmlTag(v.content)}}</div>
                <!-- <pre v-html="delHtmlTag(v.content)"></pre> -->
              </div>
              <!-- <div class="from">{{ v.sourceFrom }}</div> -->
            </li>
          </ul>
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="currentChange"
            @size-change="sizeChange"
            :page-sizes="[3, 5, 10, 20]"
            layout="prev, pager, next"
            background
            prev-text="上一页"
            next-text="下一页"
          ></el-pagination>
        </section>

        <div class="emptydom" v-else>
          <img src="@/assets/images/empty/none_pic@2x.png" />
        </div>

      </article>
    </section>
  </div>
</template>

<script>
import informNoticeApi from "@/api/informNotice";

export default {
  created() {
    this.renderList();
  },
  data() {
    return {
      searchtitle: '',
      list: [],
      // 分页相关参数
      currentPage: 1,
      pageSize: 10,
      total: 99
    };
  },
  filters: {
    
  },
  methods: {
    delHtmlTag (val) {
      return val.replace(/<[^>]+>|&[nbsp;]+/g,"")
    },
    filterTime(val) {
      let time = moment(val).format("YYYY-MM-DD")
      return time.split('-');
    },
    // 分页相关函数
    currentChange(val) {
      this.currentPage = val;
      this.renderList();
    },
    sizeChange(val) {
      this.pageSize = val;
      this.renderList();
    },

    // 渲染相关函数
    async renderList() {
      // 获取通知公告列表
      let { code, data, message } = await informNoticeApi.findInformNoticeList({
        module: 1, //所属模块(1:通知公告、2:最新动态、3:政策法规、4:下载专区、5:服务指南)
        page: this.currentPage,
        size: this.pageSize,
        sort: "asc",
        sortFiled: "sort",
        title: this.searchtitle
      });
      this.list = data.records;
      this.total = data.total;
    }
  }
};
</script>

<style lang="less" scoped>
.inform-notice {
  & > section {
    display: flex;
    
    .right-list {
      width: calc(100% - 256px);
      .search-con {
        margin-top: 20px;
      }
    }
  }
  // list
  .list {
    li {
      display: flex;
      justify-content: space-between;
      border: 1px solid #ECECF1;
      border-radius: 8px;
      height: 168px;
      padding: 24px;
      margin-top: 16px;
      .content {
        cursor: pointer;
        flex: 1;
        margin: auto 0;
        .title {
          color: #20201E;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 16px;
        }
        .text {
          color: #494A4C;
          font-size: 14px;
          line-height: 24px;
          
        }
      }
      .from {
        width: 140px;
        color: #979797;
        font-size: 14px;
      }
      .date {
        width: 120px;
        font-size: 14px;
        background: #FFF7F3;
        text-align: center;
        color: #F36E31;
        padding-top: 30px;
        margin-right: 24px;
        &>div:first-child {
          font-size: 32px;
          margin-bottom: 14px;
          font-weight: bold;
        }
        &>div:last-child {
          font-size: 16px;
        }
      }
    }
  }

  
}
</style>